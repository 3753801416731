<template>
  <div class="page-root tools-peizhi-root">
    <div class="peizhi-page">
      <div class="title">在线评估 > 快速评估 > 查询结果 > <span>参数配置</span></div>
      <div class="title-sm">车辆参数配置</div>
      <div class="result-item2">
        <div class="title"><span></span> 查询信息</div>
        <el-table size="small" :data="tableData" style="width: 100%; margin-top: 10px">
          <el-table-column prop="id" label="订单编号"> </el-table-column>
          <el-table-column prop="brand" label="品牌车系"> </el-table-column>
          <el-table-column prop="name" label="车型名称"> </el-table-column>
          <el-table-column prop="createTime" label="查询时间"> </el-table-column>
        </el-table>
      </div>
      <div class="result-item2">
        <div class="title"><span></span> 基本信息</div>
        <div class="box">
          <div class="item" v-for="(val, key, index) in list" :key="key">
            <div>{{ key == 'year' ? '年份' : key }}</div>
            <div>{{ val }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import './styles/mobile.scss';
import './styles/pc/pc.scss';
import { get } from '@/utils/axios';
export default {
  name: 'Pinggufuwu',
  mounted() {
    const { vehicleTypeId, createTime, id } = this.$route.query;
    get(`/system/type/detail/${vehicleTypeId}`).then((res) => {
      if (res.code == 200) {
        this.list = res.data;
        this.tableData = [
          {
            id,
            createTime,
            name: res.data.vehicle_type_name,
            brand: res.data.vehicle_series_name,
          },
        ];
      } else {
        this.$message.error(res.msg);
      }
    });
  },
  data() {
    return {
      tableData: [],
      list: {},
    };
  },
};
</script>
